






























.book_item {
  height: 180px;
  display: flex;
  background: #ffffff;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  .book_img {
    width: 105px;
  }
  .book_right {
    flex: 2;
    padding-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .book_name {
      font-size: 16px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .book_study {
      font-size: 14px;
      color: #faad14;
    }
    .btn_study {
      font-size: 14px;
      width: 88px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      background: #1890ff;
      color: white;
      text-align: center;
    }
  }
}
