























































































.inner_card_content {
  flex: 2;
  overflow-y: scroll;
  position: relative;
}
.card_footer {
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
