.outer_page_container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.book_container {
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}
.book_container .content_card {
  flex: 2;
  margin-top: 20px;
}
.book_container .book_img {
  height: 215px;
}
.book_container .right_box {
  margin-left: 20px;
}
.book_container .right_box .book_name {
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
}
.book_container .right_box .book_study {
  font-size: 14px;
  color: #faad14;
  margin-bottom: 20px;
}
.book_container .right_box .book_btn {
  display: inline-block;
  padding: 0 8px;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 4px;
  font-size: 12px;
  color: #1890ff;
  text-align: left;
  line-height: 20px;
  margin-bottom: 20px;
}
.book_container .right_box .book_desc {
  font-size: 14px;
  color: #333333;
}
.inner_modal_content {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
}
.inner_modal_content .book_list_container {
  position: relative;
  flex: 2;
  overflow-y: scroll;
}
.game_icon {
  font-size: 55px;
  color: #1890ff;
}
.game_title {
  font-size: 18px;
  color: #1890ff;
}
