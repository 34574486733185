





















.loading_container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  .mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.4;
  }
}
